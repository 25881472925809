.float-label {
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
    z-index: 99;
  }
  
  .as-placeholder {
    color: rgb(138, 138, 138);
  }
  
  .as-label {
    top: -8px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
  }

  .ant-input,
  .ant-input-password {
    padding: 16px 12px 4px 11px;
  }
  
  .ant-select .ant-select-selector {
    padding: 16px 10px 4px 11px;
  }
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px 10px 4px 11px;
    height: 48px;
  }
  
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
  }  
}
