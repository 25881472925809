.header {
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #5b8c9e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .header-left-item {
    display: flex;
  }

  &::after {
    content: "";
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 0;
    left: 1rem;
    height: 0px;
    border-bottom: 1px solid #dae4e7;
  }

  .header-icon {
    padding: .8em;
    background-color: #1d495b;
    border-radius: 50%;
    cursor: pointer;
  }

  .logo {
    height: 4rem;
  }

  .logo-text {
    color: #fff;
    vertical-align: middle;
    font-size: 1.2rem;

    @media (min-width: 540px) {
      font-size: 1.4rem;
    }

    @media (min-width: 1000px) {
      font-size: 1.8rem;
    }
    
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
  }
}