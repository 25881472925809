.pin-feedback {
  text-align: center;

  div {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border: 1px solid #1d495b;
    border-radius: 50%;
    margin: 0 0.5em;

    &.full {
      background-color: #1d495b;
    }
  }
}
