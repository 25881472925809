.auth-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .logo {
    width: 8rem;
    max-width: 100%;
  }

  .logo-text {
    color: #ffffff;
  }

  h1 {
    color: #ffffff;
  }

  .auth-form {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 25rem;
    margin: 0 1rem 0 1rem;
    width: 30rem;

    .forgot-password-link {
      font-style: italic;
      cursor: pointer;
      margin-top: 1rem;
    }

    .create-account-link {
      font-style: italic;
    }
  }

  .platform-authenticator-button {
    width: 5em;
    height: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}