.sign_up {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    max-height: 8rem;
  }

  .logo-text {
    color: #ffffff;
  }

  h1 {
    color: #ffffff;
  }

  &-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    color: #000000;
    width: 30rem;
    max-width: 100%;
  }

  &-link_back {
    display: flex;
    justify-content: space-between;
    
    * {
      vertical-align: middle;
    }
  }

  .platform-authenticator-button {
    width: 5em;
    height: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
}