.share_data_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 8rem;
    max-width: 100%;
  }

  h1 {
    color: #ffffff;
  }

  .share_data_panel {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    color: #000000;
    width: 32rem;
    max-width: 100%;
    margin: 0 1rem 0 1rem;

    .share_data_title {
      font-size: 1.4rem;
      color: #1d495b;

      * {
        vertical-align: middle;
      }
    }

    .share_data_invalid_container {
      width: 16rem;
      text-align: center;

      .share_data_invalid_text {
        font-size: 1.6rem;
      }
    }

    .share_data_actions {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
    }
  }
}