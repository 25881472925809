.reinit_password_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 8rem;
    max-width: 100%;
  }

  h1 {
    color: #ffffff;
  }

  .reinit_password_panel {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 32rem;
    margin: 0 1rem 0 1rem;

    .reinit_password_title {
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .reinit_password_title_error_container,
    .reinit_password_title_loading_container {
      text-align: center;
    }
  }
}