.qrscan-header {
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #5b8c9e8e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .header-icon {
    padding: .8em;
    background-color: #1d495b;
    border-radius: 50%;
    cursor: pointer;
  }

  .logo {
    height: 4rem;
  }

  .logo-text {
    color: #fff;
    vertical-align: middle;
    font-size: 1.2rem;

    @media (min-width: 540px) {
      font-size: 1.6rem;
    }

    @media (min-width: 1000px) {
      font-size: 2.4rem;
    }
    
    @media (min-width: 1200px) {
      font-size: 2.8rem;
    }
  }
}

#qr-scanner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.overlay {    
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .overlay_center {
    position: absolute;
    border: 2000px solid rgba(0, 0, 0, 0.5);
    box-sizing: content-box;

    background:
      linear-gradient(to right, #ffffff 4px, transparent 4px) 0 0,
      linear-gradient(to right, #ffffff 4px, transparent 4px) 0 100%,
      linear-gradient(to left, #ffffff 4px, transparent 4px) 100% 0,
      linear-gradient(to left, #ffffff 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, #ffffff 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, #ffffff 4px, transparent 4px) 100% 0,
      linear-gradient(to top, #ffffff 4px, transparent 4px) 0 100%,
      linear-gradient(to top, #ffffff 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;

    .line {
      width: 100%;
      height: 5px;
      background-image: radial-gradient(#ffffff, transparent);
      animation: MoveUpDown 4s ease-in-out infinite;
      position: absolute;
    }
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: calc(100% - 5px);
  }
}
