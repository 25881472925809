.validation_email_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 8rem;
    max-width: 100%;
  }

  h1 {
    color: #ffffff;
  }

  .validation_email_result {
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }
}
