.index {
  padding-top: 5rem;

  .background-gradient {
    top: 4rem;
  }

  .main {
    .menu-row {
      display: flex;
      justify-content: center;

      .menu-item {
        display: block;
        width: 10rem;
        color: #ffffff !important;
        text-decoration: none !important;
        margin: 1.6rem;
        text-align: center;
        cursor: pointer;
  
        .menu-item-image {
          margin: 0 auto;
          border-radius: 50%;
          padding: 1.6rem;
          height: 8rem;
          width: 8rem;
          background-color: #5c8798;

          img {
            max-width: 100%;
          }
        }

        .menu-item-text {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-top: 0.6rem;
        }

      }
    }
  }
}
