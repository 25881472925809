.pin_button {
  background-color: #ffffff !important;
  color: #1d495b !important;
  border: 1px solid #1d495b !important;

  &:active {
    background-color: #1d495b !important;
    color: #ffffff !important;
    border: 1px solid #1d495b !important;
  }
}

.pin_empty_space {
  display: inline-flex;
  height: 4rem;
  width: 4rem;
  margin: 0 1rem 2rem 1rem;
  vertical-align: middle;
}
