.auth_create_second_factor_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .auth_create_second_factor_container {
    background-color: #ffffff;
    padding: 1rem 2rem 0 2rem;
    border-radius: 1rem;
    color: #000000;
    max-width: 25rem;
    margin: 0 1rem 0 1rem;
    width: 30rem;

    .create_2fa_title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .create_2fa-link_back {
        cursor: pointer;
      }
    }

  }

  .platform-authenticator-button {
    width: 5em;
    height: 5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
}